<template>
  <div class="contact">
    <Navbar />
  </div>
</template>

<script>
// @ is an alias to /src
import Navbar from '../components/Navbar.vue'

export default {
  name: 'Contact',
  components: {
    Navbar
  }
}
</script>